const aioFields = [
  {label: "AIO Filtered US", dataField: "aiofiltered"},
  {label: "AIO Filtered EU", dataField: "aiofilteredeu"},
  {label: "AIO UGG UK", dataField: "aiougguk"},
  {label: "EQL Raffles", dataField: "eql"},
  {label: "Footsites US", dataField: "footsites"},
  {label: "Footsites CA", dataField: "footsitesca"},
  {label: "Footlocker EU + UK", dataField: "footlockereu"},
  {label: "Footlocker UK", dataField: "footlockeruk"},
  {label: "Footlocker AU", dataField: "footsitesau"},
  {label: "Footlocker NZ", dataField: "footsitesnz"},
  {label: "Footlocker KR", dataField: "footsiteskr"},
  {label: "Footlocker MY", dataField: "footsitesmy"},
  {label: "Footlocker TH", dataField: "footsitesth"},
  {label: "Footsites Release Calendar", dataField: "footsitesreleases"},
  {label: "Footlocker UK Release Calendar", dataField: "footsitesreleases_uk"},
  {label: "Footsites Queue", dataField: "footsitesqueue"},
  {label: "Footsites V2", dataField: "footsitescheckoutlogs"},
  {label: "Mesh Raffles", dataField: "footpatrol_raffles"},
  {label: "Schuh UK", dataField: "schuh_uk"},
  {label: "Kith Raffles", dataField: "kith_raffles"},
  {label: "Urban Outfitters / Anthropologie", dataField: "urbanoutfitters"},
  {label: "Mesh EU", dataField: "mesh"},
  {label: "Mesh UK", dataField: "meshUK"},
  {label: "Mesh Asia", dataField: "meshAS"},
  {label: "Arcteryx US", dataField: "arcteryx_us"},
  {label: "Arcteryx CA", dataField: "arcteryx_ca"},
  {label: "Arcteryx GB", dataField: "arcteryx_gb"},
  {label: "Arcteryx KR", dataField: "arcteryx_kr"},
  {label: "The North Face KR", dataField: "tnf_kr"},
  {label: "MSCHF", dataField: "mschf"},
  {label: "BSTN", dataField: "bstnfiltered"},
  {label: "Complex", dataField: "complex"},
  {label: "Foot Asylum", dataField: "footasylum"},
  {label: "Pro Direct UK", dataField: "prodirect"},
  {label: "UGG US", dataField: "ugg"},
  {label: "UGG UK", dataField: "ugg_uk"},
  {label: "Hoka UK", dataField: "hoka_uk"},
  {label: "Chrome Hearts", dataField: "chrome_hearts"},
  {label: "Reebok", dataField: "reebok"},
  {label: "Vans", dataField: "vans"},
  {label: "Salomon", dataField: "salomon"},
  {label: "Crocs", dataField: "crocs"},
  {label: "Mesh Unfiltered", dataField: "meshunfiltered"},
    {label: "Snipes USA", dataField: "snipesusafiltered"},
  {label: "Shiekh", dataField: "sheikh"},
    {label: "Asos US", dataField: "asosfiltered"},
    {label: "Asos GB", dataField: "asosfilteredgb"},
    {label: "Asos AU", dataField: "asosfilteredau"},
  {label: "Solebox Filtered", dataField: "soleboxfiltered"},
  {label: "Kickz", dataField: "kickz"},
    {label: "Shelta EU", dataField: "sheltaeu"},
    {label: "New Balance US", dataField: "newbalance"},
    {label: "New Balance KR", dataField: "newbalancekr"},
    {label: "Consortium UK", dataField: "consortium"},
  {label: "END", dataField: "endfiltered"},
  {label: "Goat Drops", dataField: "goat_app"},
  { label: "ABC-Mart KR", dataField: "abcmart_kr"},
  { label: "ABC-Mart JP", dataField: "abcmart_jp"},
    {label: "Trefac JP", dataField: "trefac"},
    {label: "Tillys", dataField: "tillys"},
      {label: "Kingswell.tv", dataField: "kingswelltv"},
      {label: "Under Armour", dataField: "under_armour"},
    {label: "Scotty Cameron", dataField: "scotty"},
    {label: "Scotty Cameron Museum", dataField: "scotty_museum"},
  {label: "Mr Porter/Net a Porter US", dataField: "porterusfiltered"},
  {label: "Mr Porter/Net a Porter EU", dataField: "portereufiltered"},
  {label: "Hibbett", dataField: "hibbett"},
  {label: "Hibbett New Launches", dataField: "hibbettLaunches"},
  {label: "Ssense", dataField: "ssensefiltered"},
  {label: "Snipes EU", dataField: "snipes"},
  {label: "Stone Island", dataField: "stoneisland"},
  {label: "Rukus103", dataField: "rukus"},
  {label: "Kicks Lounge", dataField: "kickslounge"},
    {label: "SNS", dataField: "sns"},
    {label: "SNS UK", dataField: "sns_uk"},
    {label: "Revolve", dataField: "revolve"},
  {label: "Nordstrom", dataField: "nordstromfiltered"},
  {label: "Zalando", dataField: "zalando"},
  {label: "Pacsun", dataField: "pacsun"},
  {label: "Pacsun Discounts", dataField: "pacsun_discounts"},
  {label: "Wtaps", dataField: "wtaps"},
  {label: "Cown Town Skate", dataField: "cowtownskateboards"},
  {label: "Home Gown Skate", dataField: "homegownskate"},
  {label: "Post Modern Skate", dataField: "postmodern"},
  {label: "Jerrics Skate", dataField: "jerrics"},
  {label: "HoopCity KR", dataField: "hoopcitykr"},
  {label: "Asics US", dataField: "asics"},
  {label: "Asics AU", dataField: "asicsau"},
  {label: "Asics TH", dataField: "asicsth"},
  {label: "Asics JP", dataField: "asicsjp"},
  {label: "Asics KR", dataField: "asicskr"},
  {label: "Asics SG", dataField: "asicssg"},
  {label: "Asics MY", dataField: "asicsmy"},
  {label: "Asics PH", dataField: "asicsph"},
  {label: "Tactics", dataField: "tactics"},

  {label: "Seven Store", dataField: "sevenstore"},
  {label: "LVRG + Capitalist", dataField: "lvrg_capitalist"},
  {label: "43einhalb", dataField: "43einhalb"},
  {label: "Offspring", dataField: "offspringfiltered"},
  {label: "Office", dataField: "Office"},
  {label: "Self Ridges", dataField: "selfridges"},
 
  {label: "Finishline/JDSports US", dataField: "finishlinefiltered"},
  {label: "Finishline Queue", dataField: "finishline_queue"},
  {label: "HBX", dataField: "hbx"},
  {label: "Sun Day Red", dataField: "sundayred"},
  {label: "AW-Lab", dataField: "awlab"},
  {label: "Dicks Sporting Goods Sneakers", dataField: "dsgsneakers"},
  {label: "Skate Warehouse", dataField: "skatewarehouse"},
  {label: "Skate Park of Tampa", dataField: "skateparktampa"},
  {label: "Anchor Skate", dataField: "anchorskate"},
  {label: "Mortar Tokyo", dataField: "mortartokyo"},
  {label: "Instant SB (JP)", dataField: "instantsb"},
  {label: "Daytona Park (JP)", dataField: "daytona_park"},
  {label: "Saucony", dataField: "saucony"},
  {label: "SVD", dataField: "sivasfiltered"},
  {label: "18 Montrose", dataField: "18montrose"},
  {label: "Wilson", dataField: "wilson"},
  {label: "Sam Tabak", dataField: "samtabak"},
  {label: "Skate Deluxe", dataField: "skatedeluxe"},
  {label: "MyTheresa", dataField: "mytheresa"},
  {label: "Worksout KR", dataField: "worksout"},
  {label: "Smart Store Asics KR", dataField: "asics_smartstore"},
  {label: "Smart Store Adidas KR", dataField: "adidas_smartstore"},
  {label: "Smart Store KDB KR", dataField: "kdb_smartstore"},
  {label: "Fast Times AU", dataField: "fasttimes"},
  {label: "Exploit Skate", dataField: "exploitskate"},
  {label: "Supply Store AU", dataField: "supply_store_au"},
  {label: "Hype DC AU", dataField: "hype_dc_au"},
  { label: "Epoch Mormal (JP)", dataField: "epoch_mormal"},
  { label: "Verdys Giftshop (JP)", dataField: "vgiftshop"},
  {label: "Folder (JP/KR)", dataField: "folder"},
  {label: "Take Five (JP)", dataField: "takefivejp"},
  {label: "Greenhouse Outdoor Flips (JP)", dataField: "ysgreenh"},
  {label: "My Navy Exchange", dataField: "mynavyexchange"},
  {label: "Taf MX", dataField: "tafmx"},
  {label: "Atmos KL", dataField: "atmoskl"},
  {label: "Atmos KL + Hoopstation Sales", dataField: "atmoshoopsdiscount"},
  {label: "Hoop Station", dataField: "hoopsstation"},
  {label: "Mvp Basketball Store", dataField: "mvpbasketball"},
  {label: "PALPAL Skate (KR)", dataField: "palpalkr"},
  {label: "Kasina (KR)", dataField: "kasinakr"},
  {label: "SSF Shop (KR)", dataField: "ssfshop"},
  {label: "REV (TH)", dataField: "rev_th"},
  {label: "ON (TH)", dataField: "on_th"},
  {label: "New Balance (TH)", dataField: "newbalance_th"},
  {label: "Seek Thailand (TH)", dataField: "seek_th"},
  {label: "Slammers (TH)", dataField: "slammers_rev_th"},
  {label: "Ari Running Store (TH)", dataField: "ari_running_th"},
  {label: "Gentle Monster (JP)", dataField: "gentlemonster"},
  {label: "Gentle Monster (KR)", dataField: "gentlemonsterkr"},
  {label: "Jentestore (KR)", dataField: "jentestore"},
  {label: "OkMall (KR)", dataField: "okmall"},
  {label: "VegNonVeg", dataField: "vegonveg"},
  {label: "ZOZO JP", dataField: "zozojp"},
  {label: "Usagi Online JP", dataField: "usagi"},
  {label: "Yamaotoko JP", dataField: "yamaotoko"},
  {label: "Emmi JP", dataField: "emmi"},
  {label: "Lesportsac JP", dataField: "lesportsac"},
  {label: "Kapital-Webshop JP", dataField: "kapital_webshop"},
  {label: "Asics JP", dataField: "asicsjp"},
  {label: "Everyone Tokyo (JP)", dataField: "everyonetokyo"},
  {label: "California Street (JP)", dataField: "calistreet"},
  {label: "Ennoy Pro (JP)", dataField: "ennoy"},
  {label: "Billys Tokyo (JP)", dataField: "billys_tokyo"},

  {label: "Spotaka (JP)", dataField: "spotaka"},
  {label: "Atmos Tokyo JP", dataField: "atmostokyo"},
  {label: "Hi5 Skateboarding JP", dataField: "hi5skate"},
  {label: "Super Sports JP", dataField: "supersports"},
  {label: "Gallery2 JP", dataField: "gallery2jp"},
  {label: "God Selection XXX JP", dataField: "god_selection_xxx"},
  {label: "Batsu Skateboard Shop JP", dataField: "batskuskate"},
  {label: "Jack Ocean Sports", dataField: "jacksurf"},
  {label: "Canola Skate Shop JP", dataField: "canolaskate"},
  {label: "Soccer Shop Kamo JP", dataField: "sskamo"},
  {label: "Dimmoire JP", dataField: "dimmoire"},
  { label: "Beams (JP)", dataField: "beams"},
  { label: "United Arrows (JP)", dataField: "united_arrows"},
      {label: "Platinum Co. JP (www.platinum.beer)", dataField: "platinumcojp"},
      {label: "agh.theshop.jp", dataField: "aghtheshopjp"},

 // {label: "Off-White", dataField: "offwhite"}
];

export default aioFields;
